// @import '../../styles/app';

// /* Gradient color format for #28d456 */
// $green-start: #28d456;
// $green-end: #6bc992;
// $gradient-color: linear-gradient(to right, $green-start, $green-end);

// .widget {
//   display: flex;
//   position: relative;
//   margin-bottom: 30px;
//   padding: $widget-padding-y $widget-padding-x;
//   background: $widget-bg;
//   transition: background-color 0.3s ease; /* Add transition for smooth hover effect */

//   &:hover {
//     background: $gradient-color; /* Change background color on hover */
//   }

//   .title, .value, .icon {
//     transition: color 0.3s ease; /* Add transition for smooth color change on hover */
//   }

//   &:hover .title,
//   &:hover .value,
//   &:hover .icon {
//     color: white; /* Change color to white on hover */
//   }
// }

// .icon {
//   flex: 0 0 auto;
//   margin-right: 20px;
//   font-size: 2.1rem; /* Increase the font size of the icon */
//   color: $green-start; /* Default icon color */
//   font-weight: bolder;
// }

// /* Define specific colors for each icon */
// .icon-color {
//   color: $green-start; /* Green color for the bank icon */
// }

// /* Add more classes for other icons and their respective colors as needed */

// .content {
//   flex: 1;
// }

// .title {
//   margin-top: 0;
//   margin-bottom: 1.5rem / 2;
//   color: $widget-title-color;
//   font-size: 0.9rem;

//   @include clearfix();
// }


@import '../../styles/app';

/* Gradient color format for #28d456 */
$green-start: #28d456;
$green-end: #6bc992;
$gradient-color: linear-gradient(to right, $green-start, $green-end);

.widget {
  display: flex;
  flex-direction: column;
  position: relative;
  border:none;
  margin-bottom: 20px;
  padding: $widget-padding-y $widget-padding-x;
  background: $widget-bg;
  height: 70px;
  overflow: hidden;
  // border-radius: 5px; // You can adjust the value as needed
  border-radius: 10px;
  // Box shadow added here
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out; // Optional: Add transition effect
  
  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .iconWrapper {
    border-radius: 25%;
    overflow: hidden;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .icon {
    font-size: 1.5rem;
    color: white;
    padding: 2px;
  }

  .titleContainer,
  .valueContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 30%;
    font-size: 0.9rem;
    white-space: pre-wrap;
    height: 40px;
  }

  .titleContainer {
    color: $widget-title-color;
    margin-top: 0.4rem;
    margin-bottom: 0.6rem;
  }

  .valueContainer {
    margin-left: auto;
    color: $widget-title-color;
    font-size: 1rem;
    white-space: nowrap;
    justify-content: space-between;  // Distribute space between TSH and value
    align-items: flex-end;  // Align items to the bottom
  }

  .tshLabel {
    font-size: 0.7rem;
    margin-left: auto;  // Push to the rightmost side
    margin-right: 0.1rem;
    color: rgba(0, 0, 0, 0.5); // Faint color with reduced opacity
  }

  .content {
    flex: 1;
  }
}





















