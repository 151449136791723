// @import '../../../styles/app';

// .headerLink {
//   a {
//     display: flex;
//     color: $gray-500;
//     align-items: center;
//     text-decoration: none;
//     cursor: pointer;

//     &:hover,
//     &:active,
//     &:focus {
//       font-weight: $font-weight-normal;
//     }
//   }

//   > a {
//     position: relative;
//     padding: 0 15px;
//     line-height: 55px;
//     justify-content: space-between;

//     &:hover {
//       font-weight: $font-weight-thin;
//       background-color: rgba(#000, 0.01);
//     }

//     img {
//       width: 15px;
//       margin-right: 15px;
//     }
//   }
// }

// .arrow {
//   color: $gray-400;
//   transition: 0.3s;
//   right: 0;
// }

// .arrowActive {
//   transform: rotate(-90deg);
// }

// .headerLinkActive {
//   span {
//     color: $brand-active;
//     font-weight: $font-weight-semi-bold;
//   }
// }

// .panel {
//   border: none;
//   box-shadow: none;
//   margin: 0;
//   border-radius: 0;
//   background: rgba(#000, 0.01);

//   div {
//     padding: 0;
//   }

//   ul {
//     padding-left: 0;
//     font-size: $font-size-small;

//     li {
//       padding: 3px 0;
//       list-style: none;
//     }

//     a {
//       padding: 4px 20px 4px 44px;

//       &:hover,
//       &:active,
//       &:focus {
//         font-weight: $font-weight-semi-bold;
//         color: $brand-success;
//       }
//     }
//   }
// }


// 5ae833 -light green palette
//#208016 - dark green palette
// #3754a5 -Darkblue
//#5d80f9 -LightBlue 
//#e3206b -Pink
//#7c168a -Purple




@import '../../../styles/app';

.headerLink {
  a {
    display: flex;
    color: $gray-500;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover,
    &:active,
    &:focus {
      font-weight: $font-weight-normal;
    }
  }

  > a {
    position: relative;
    padding: 0 15px;
    line-height: 55px;
    justify-content: space-between;

    &:hover {
      // background-color: rgba(#000, 0.01);
      background-color: transparent;
    }

    &.headerLinkActive,
    &.headerLinkActive:hover {
      background-color:  var(--theme-color)!important;
      color: white;
      // font-weight: $font-weight-semi-bold;
      font-weight: bold;
      border-radius: 12px;
      margin: 9px 9px;
      padding: 0 6px;
      line-height: 40px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.05);

      .iconCard {
        background-color: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      }

      .linkIcon {
        color: var(--theme-color);
      }
    }

    > div {
      display: flex;
      align-items: center;
    }

    .iconCard {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border-radius: 8px;
      margin-right: 10px;
      transition: background-color 0.3s ease, box-shadow 0.3s ease;
      box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
    }

    .linkIcon {
      margin: 0;
      color: #bdbdbd; // Set the default icon color to #969595
    }

    span {
      margin-left: 8px;
    }

    .arrow {
      margin-right: 5px;
      font-size: 0.6rem;
      transition: transform 0.3s ease;
    }
  }
}

.headerLinkActive {
  a, a:hover {
    background-color: var(--theme-color) !important;
    color: white;
    // font-weight: $font-weight-semi-bold;
    font-weight: bold;
    border-radius: 4px;

    .iconCard {
      background-color: white !important;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    .linkIcon {
      color: var(--theme-color);
    }

    * {
      color: var(--theme-color);
    }
  }
}

.panel {
  border: none;
  box-shadow: none;
  margin: 0;
  border-radius: 0;
  // background: rgba(#000, 0.01);
  background-color: transparent;

  div {
    padding: 0;
  }

  ul {
    padding-left: 0;
    font-size: $font-size-small;

    li {
      padding: 3px 0;
      list-style: none;
    }

    a {
      padding: 4px 25px 4px 49px;
      display: flex;
      align-items: center;

      span {
        margin-left: 8px; // Added left margin to the text
      }

      &:hover,
      &:active,
      &:focus {
        font-weight: $font-weight-semi-bold;
        color: var(--theme-color);

        i.fa-dot-circle-o {
          color: var(--theme-color);
        }
      }
    }
  }
}

.linkIcon {
  transition: color 0.3s ease;
}

.arrowActive {
  transform: rotate(0deg);
}

.dotIcon {
  font-size: 0.5rem; // This sets the size to 8px if the base font size is 16px
  margin-right: 12px;
  color: $gray-500;
}


