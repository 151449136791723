@import '../../styles/app';

.root {
  width: $sidebar-width-open;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  // background-color: $sidebar-bg-color;
  background-color: #fff;
  color: $sidebar-color;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15%;
  width: 100%;
  background: 'white';
  a img {
    width: $sidebar-width-open - 60px;
  }
}

.companyName {
  font-size: 0.85em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 35px;
  margin-top: -13px;
}

.nav {
  padding: 10px 0;
}

.navWrapper {
  height: calc(100vh - 100px);
  overflow-y: auto;

  /* Customize scrollbar */
  scrollbar-width: none; /* Hide scrollbar for Firefox */

  /* Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    width: 1px; /* Extremely thin */
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent; /* Semi-transparent thumb color */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: transparent; /* Slightly more opaque on hover */
  }
}

.divider {
  border-top: 1px solid rgba(128, 128, 128, 0.05);
  margin-top: 10px;
  margin-bottom: 10px;
}

