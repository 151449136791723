@import '../../styles/app';

.widget {
  display: block;
  position: relative;
  margin-bottom: 30px;
  padding: $widget-padding-y $widget-padding-x;
  background: $widget-bg;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.05);
}

.title {
  margin-top: 0;
  margin-bottom: 1.5rem / 2;
  color: $widget-title-color;
  font-weight: bold;
  position: relative;
  padding-left: 12px; // Reduced padding to move title closer to indicator

  @include clearfix();
}

.greenIndicator {
  position: absolute;
  left: -$widget-padding-x; // Pushes the indicator to the edge of the widget
  top: 50%;
  transform: translateY(-50%);
  width: 4px; // Adjust width as needed
  height: 80%; // Adjust height as needed
  background-color: var(--theme-color); // Bright green color, adjust as needed
  border-radius: 0 2px 2px 0; // Rounded corners on the right side only
}

.divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.04); // Faint color for the divider
  margin: 10px -#{$widget-padding-x}; // Negative margin to extend to widget edges
}

.content {
  padding-top: 10px; // Add some space after the divider
}